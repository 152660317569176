<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/csm_Ersa-Selective-VERSAEYE-01-900x675_8336aa5341.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
              <br><br><br>
              <div class="row">
                <div class="col-2"></div>
                <div class="mt-1 col-6">
                  <h1 class="font-size-48 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>
                 Perfectly documented solder joint quality especially for the inspection of THT-solder joints.
                  </h2>
                </div>
                </div>
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br />
            <div class="row">
              <div class="col-xl-6">
                <br /><br />
                <div class="mt-4">
                  <h1>Tech-Highlights VERSAEYE</h1>
                  <br />
                  <ul class="container">
                    <li>
                      <h4>
                     System optimized for wave and selective soldering systems
                      </h4>
                    </li>
                    <br />
                    <li>
                      <h4>Compatible with work piece carriers</h4>
                    </li>
                      <br />
                    <li>
                      <h4>Modular inspection possible: bottom, top or bottom & top</h4>
                    </li>
                     <li>
                      <h4>
                    Available with up to 9 cameras per module
                      </h4>
                    </li>
                    <br />
                     <li>
                      <h4>
                   I/O interface
                      </h4>
                    </li>
                    <br />
                     <li>
                      <h4>
                     Classification of inspection results at all times
                      </h4>
                    </li>
                    <br />
                  </ul>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/21csm_Ersa-Selective-SMARTFLOW-006-900x600_ae6f26d723.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br />

           

          

          
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>